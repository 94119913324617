
import type { MenuItem } from './types';
import MenuLink from './MenuLink.vue';
import TransitionCollapse from '@/core/components/TransitionCollapse/TransitionCollapse.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'MenuItem',
  
  components: {
    MenuLink,
    TransitionCollapse
  },

  props: {
    item: {
      type: Object as () => MenuItem,
      required: true
    }
  },

  emits: ['hide-menu', 'expand'],

  data() {
    return {
      isExpanded: false
    }
  },

  computed: {
    hasChildren() {
      return this.item.children?.length
    },
    ...mapGetters({
      deviceIs: 'core$browser/deviceIs',
      sidebarCollapsed: 'core$menu/sidebarCollapsed',
    }),
  },

  methods: {
    onRootItemClick() {
      if (this.hasChildren) {
        this.isExpanded = !this.isExpanded;
      }
    }
  },

  watch: {
    isExpanded(value) {
      this.$emit('expand');
    },
  }
}
