// @ts-nocheck
import { companyAPI } from '@/core/api/company';
import { isEmpty, uniq } from 'lodash';
import { AppStagesEnum } from '@/core/data/App-Stages';
import router from '@/core/router';
import { getTimeZone } from '@/core/utils/timeZone';
import { getI18n } from '@/core/i18n';

const redirect = (opts) => {
  const { path } = opts;
  if (router.currentRoute.path === path) return Promise.resolve(false);

  return router.replace(opts);
};

const stringifyOffset = (offset) => {
  const sign = offset > 0 ? '+' : '';

  return `${sign + offset} UTC`;
};

const state = {
  loading: false,
  stage: AppStagesEnum.INIT,
  settings: null,
  settingsV2: null,
  completedStages: [],
  partyList: [],
  firstRoute: null,
  allowedTypes: [],
  timeZones: {},

  currencyList: {},
  taxSystems: {},

  //new____
  units: [],
  //______
  //carts_____
  isTv: false,
  isSupport: false,
  //_____
  serviceNotifications: {}
};

const getters = {
  //new____
  units: (state) => state.units.map((unit) => ({ ...unit, title: getI18n().t(unit.title) })),
  settings: (state) => state.settings,
  settingsV2: (state) => state.settingsV2,
  isTv: (state) => state.isTv,
  isSupport: (state) => state.isSupport,

  //______
  isOnlineCartsEnabled: (state) => state.settingsV2?.features.is_online_carts_enabled,
  isSearchTableEnabled: (state) => state.settingsV2?.features.is_table_search_enabled,
  isStoreQrCodeSectionEnabled: (state) => state.settingsV2?.features.is_store_settings_qr_enabled,
  isSupportChatEnabled: (state) => state.settingsV2?.features.is_support_chat_enabled,
  isDevicesEnabled: (state) => state.settingsV2?.features.is_devices_menu_available,
  isOrderEventsEnabled: (state) => state.settingsV2?.features.is_order_events_enabled,
  isXmasEnabled: (state) => state.settingsV2?.features.is_xmas_enabled,
  isTimelineEnabled: (state) => state.settingsV2?.features.is_timeline_enabled,

  loading: (state) => state.loading,
  firstRoute: (state) => state.firstRoute,
  stage: (state) => state.stage,
  settings: (state) => state.settings,
  mailingsAccess: (state) => !!(state.settings && state.settings.is_push_distribution_enabled),
  refundAccess: (state) => !!(state.settings && state.settings.is_refund_enable),
  // refundAccess: (state) => true,
  isComplete: (state) => (stage) => state.completedStages.includes(stage),
  isReady: (state) => state.stage === AppStagesEnum.READY,
  allowedTypes: (state) => state.allowedTypes,
  partyList: (state) => state.partyList,
  taxSystems: (state) =>
    Object.entries(state.taxSystems).map(([value, title]) => ({
      value,
      title,
    })),
  timezonesRaw: (state) => state.timeZones,
  timeZoneList: (state, getters) =>
    Object.keys(state.timeZones).map((timeZone) => {
      const stringifyOffset = getters.timeZoneOffset(timeZone, { stringify: true });

      return {
        name: `${timeZone.replace(/_/g, ' ')} ${stringifyOffset}`,
        id: timeZone,
        meta: { timeZone },
      };
    }),

  timeZoneOffset:
    (state) =>
    (tz = getTimeZone(), opts = {}) => {
      const { stringify = false } = opts;
      if (!state.timeZones.hasOwnProperty(tz)) return '';

      const offset = state.timeZones[tz];
      const offsetInHours = offset ? -(offset / 60 / 60) : offset;

      return stringify ? stringifyOffset(offsetInHours) : offsetInHours;
    },
  internationalPartyList: (state) => state.partyList.filter((party) => !party.type_data),
  currencyList: (state) => state.currencyList,
  serviceNotifications: (state) => state.serviceNotifications,
};

const actions = {
  //new____
  setUnitsList: async ({ commit }) => {
    try {
      const unitList = await companyAPI.getUnitsCode();
      commit('_setUnitsList', unitList);
    } catch (error) {}
  },

  initSettings: async ({ commit, state }) => {
    if (state.settings) return;

    const settings = await companyAPI.getSettings();
    const settingsV2 = await companyAPI.getSettingsV2();

    commit('_setSettings', settings);
    commit('_setSettingsV2', settingsV2);
  },
  //__________
  globalInit: async ({ dispatch, state, commit }, opts = {}) => {
    if (state.loading) return;
    commit('setLoading', !opts.silent);

    try {
      await dispatch('_init', opts);
    } catch (error) {
      commit('setStage', AppStagesEnum.ERROR);
      //TODO: redirect on fail page
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },

  _init: async ({ commit, dispatch, getters }, opts = {}) => {
    const { silent = false, currentRoute } = opts;
    try {
      await dispatch('core$menu/getMenu', null, { root: true });
      await dispatch('initCompany');
      await dispatch('initAllowedtypes');
      await dispatch('initSettings');
    } catch (error) {
      router.push('/logout');
    }

    // await dispatch('initProfile');
    await dispatch('fetchTimeZones');
    await dispatch('fetchParty');
    // await dispatch('fetchTaxSystems');

    let checkMap = [{ action: 'checkRegistration', stage: AppStagesEnum.REGISTRATION }];

    for (let { action, stage } of checkMap) {
      if (getters.isComplete(stage)) continue;

      const { complete, handler } = await dispatch(action);
      if (complete) {
        commit('addCompletedStage', stage);
        continue;
      }

      if (!getters.firstRoute) {
        commit('_setFirstRoute', currentRoute);
      }
      commit('setStage', stage);
      await handler();
      return;
    }

    // IS_DEV &&
    //   (await dispatch(
    //     'trade-point$create/init',
    //     { storeId: 6024, storeGroupId: 5998 },
    //     { root: true },
    //   ));
    commit('setStage', AppStagesEnum.READY);
    commit('_setFirstRoute', currentRoute);

    const successRedirectRoute = await dispatch('getSuccessRedirectRoute');

    if (silent) return;
    if (successRedirectRoute) {
      await redirect(successRedirectRoute);
    }
  },

  completeStage: ({ commit, dispatch }, { stage, ...restOpts }) => {
    commit('addCompletedStage', stage);
    return dispatch('_init', restOpts);
  },

  unsetStage: ({ commit, dispatch }, { stage, ...restOpts }) => {
    commit('removeCompletedStage', stage);
    return dispatch('init', restOpts);
  },

  getSuccessRedirectRoute: async ({ getters, rootGetters }) => {
    const firstRoute = getters.firstRoute ? getters.firstRoute : { path: '/home' };

    try {
      // const storeList = await companyAPI.getStoreList({ limit: 1, page: 1 });
      // const hasStores = !!storeList.items.length;
      // return hasStores ? firstRoute : { name: 'trade-point$create' };
      return firstRoute;
      // Если нету ни одной торговой точки перекинуть на создание
    } catch (error) {
      return firstRoute;
    }
  },

  initCompany: ({ dispatch, rootGetters }) => {
    const company = rootGetters['core$company/company'];
    if (!isEmpty(company)) return;

    return dispatch('core$company/init', null, { root: true });
  },

  initAllowedtypes: async ({ commit, state }) => {
    if (state.allowedTypes.length) return;

    const allowedTypes = await companyAPI.getStoreAcceptedTypes();
    commit('setAllowedTypes', allowedTypes);
  },

  initProfile: ({ dispatch, rootGetters }) => {
    const profile = rootGetters['core$profile/profile'];
    if (!isEmpty(profile)) return;

    return dispatch('core$profile/init', null, { root: true });
  },

  fetchParty: async ({ commit, getters }) => {
    const { partyList } = getters;
    if (partyList.length) return;

    const { items: parties } = await companyAPI.getExchangePartyListOld({
      limit: 9999,
    });
    commit('setPartyList', parties);
  },

  fetchTaxSystems: async ({ commit, getters }) => {
    if (getters.taxSystems.length) return;

    const taxSystems = await companyAPI.getAvailableTaxSystems();
    commit('setTaxSystems', taxSystems);
  },

  checkRegistration: async ({ getters }) => {
    const handler = () => redirect({ path: '/registration' });
    const hasParty = !!getters.partyList.length;

    return { complete: hasParty, handler };
  },

  updatePartyInPartyList({ commit, getters }, updatedParty) {
    const updatedPartyList = getters.partyList.map((party) => {
      return party.id === updatedParty.id ? updatedParty : party;
    });

    commit('setPartyList', updatedPartyList);
  },

  fetchTimeZones: async ({ commit, getters }) => {
    if (getters.timeZoneList.length) return;

    const timeZones = await companyAPI.getTimeZoneList();
    commit('setTimeZones', timeZones);
  },

  initCurrency: async ({ commit, getters }) => {
    if (isEmpty(getters.currencyList)) {
      const currencyList = await companyAPI.getCurrencyCode();
      commit('setCurrencyList', currencyList);
    }
  },
  setTvMode: async ({ commit }, isTv) => {
    commit('setTvMode', isTv);
  },

  getServiceNotifications: async ({commit}) => {
    const serviceNotifications = await companyAPI.getServiceNotifications();
    commit('setServiceNotifications', serviceNotifications);
  }
};

const mutations = {
  //new____
  _setUnitsList: (state, unitList) => {
    state.units = unitList.map((unit) => {
      return {
        value: unit,
        title: `unit_${unit}`,
      };
    });
  },
  _setSettings: (state, settings) => (state.settings = settings),

  _setSettingsV2: (state, settingsV2) => (state.settingsV2 = settingsV2),
  //_____
  _setFirstRoute: (state, value) => (state.firstRoute = value),

  setLoading: (state, value) => (state.loading = value),

  setInit: (state, value) => (state.init = value),

  setStage: (state, stage) => (state.stage = stage),

  setPartyList: (state, partyList) => (state.partyList = partyList),

  setTaxSystems: (state, taxSystems) => (state.taxSystems = taxSystems),

  setAllowedTypes: (state, allowedTypes) => (state.allowedTypes = allowedTypes),

  setSettings: (state, settings) => (state.settings = settings),

  setTimeZones: (state, timeZones) => (state.timeZones = timeZones),

  setCurrencyList: (state, list) => (state.currencyList = list),

  setIsSupport: (state) => (state.isSupport = true),

  addCompletedStage: (state, stage) => {
    state.completedStages = uniq([...state.completedStages, stage]);
  },

  removeCompletedStage: (state, stage) => {
    state.completedStages = state.completedStages.filter((_stage) => _stage !== stage);
  },
  setTvMode: (state, payload) => {
    state.isTv = payload;
  },
  setServiceNotifications: (state, serviceNotifications) => state.serviceNotifications = serviceNotifications,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
