import * as Sentry from '@sentry/vue';

// eslint-disable-next-line import/prefer-default-export
export const setupSentry = ({ app, router, dsn, disabled, environment = process.env.NODE_ENV }) => {
  if (disabled) return;
  Sentry.init({
    app,
    dsn,
    environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^\/api\//],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });
}
