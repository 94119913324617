<script>
import { useRoute } from 'vue-router';
import { Icon } from '@brskl/ui-lib';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'MenuLink',
  
  components: {
    Icon
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapMutations({
      setSidebarCollapsed: 'core$menu/setSidebarCollapsed',
    }),
  },

  setup() {
    const currentRoute = useRoute();

    const isActiveRoute = (checkRoute) => {
      if (!checkRoute) return false;
      if (typeof currentRoute.name !== 'string') return false;
      return currentRoute.name.split('$')[0] === checkRoute.name.split('$')[0];
    };

    return {
      isActiveRoute
    };
  },

  computed: {
    ...mapGetters({
        deviceIs: 'core$browser/deviceIs',
        sidebarCollapsed: 'core$menu/sidebarCollapsed',
    }),
    sidebar() {
      if (this.sidebarCollapsed) return '';
      if (!this.item.sidebar) return '';
      return `(${this.item.sidebar})`;
    },
    componentName() {
      if (!this.item.route) return 'div';
      return this.item.route.external ? 'a' : 'RouterLink';
    },

    componentAttributes() {
      return {
        'a': {
          href: this.item.route?.external,
        },
        'RouterLink': {
          to: this.item.route,
          class: { 'router-link-active': this.isActiveRoute(this.item.route) }
        },
      }[this.componentName] || {};
    }
  }
};
</script>

<template>
  <component :is="componentName" v-bind="componentAttributes" class="link" @click="setSidebarCollapsed(true)">
    <Icon v-if="item.icon" :name="item.icon" class="icon" />
    <span :data-device="deviceIs" :data-collapsed="sidebarCollapsed" class="title" v-if="deviceIs !== 'desktop' ? sidebarCollapsed : true">{{ item.title }}</span>
  </component>
</template>


<style lang="scss" scoped>
@import '@/core/styles/mixins';

[data-device="desktop"] {
  margin-left: 12px
}

.link {
  display: flex;
  align-items: center;
  position: relative;
  padding: rem(8px);
  color: #515C72;
  cursor: pointer;
  text-decoration: none;

  &.router-link-active {
    color: #22C951;
  }

  .svg-icon {
    margin-right: rem(12px);
    height: rem(24px);
    width: rem(24px);
  }
}

.icon {
  max-width: rem(24px);
  flex: 1 0 24px;
}

.title {
  flex: 0 1 auto;
}
</style>
