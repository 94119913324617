import { companyAPI } from '@/core/api/company';

const state = {
  menu: [],
  sidebarCollapsed: false
};

const getters = {
  menu: (state) => state.menu,
  sidebarCollapsed: (state) => state.sidebarCollapsed,
};
const actions = {
  getMenu: async ({ commit }) => {
    const menu = await companyAPI.getMenu();
    commit('_setMenu', menu);
  },
};
const mutations = {
  _setMenu: (state, payload) => (state.menu = payload),
  setSidebarCollapsed: (state, value) => state.sidebarCollapsed = value,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
