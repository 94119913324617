import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-12046d6a")
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "text"
}
const _hoisted_3 = { class: "buttons" }
_popScopeId()

import { computed } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { getI18n } from '@/core/i18n';

interface Props {
  closeText?: string,
  confirmText?: string,
  text?: string,
}

interface Emits {
  'close': void,
  'action': void,
}


export default _defineComponent({
  props: {
    closeText: { type: String, required: false },
    confirmText: { type: String, required: false },
    text: { type: String, required: false }
  } as unknown as undefined,
  setup(__props: {
  closeText?: string,
  confirmText?: string,
  text?: string,
}, { emit: emits }: { emit: ({
  'close': void,
  'action': void,
}), expose: any, slots: any, attrs: any }) {

const props = __props
const i18n = getI18n();





const closeText = computed(() => props.closeText || i18n.t('common.cancel'));
const confirmText = computed(() => props.confirmText || i18n.t('common.delete'));

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.text)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.text), 1))
      : (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 })
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('close')))
      }, _toDisplayString(_unref(closeText)), 1),
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('confirm')))
      }, _toDisplayString(_unref(confirmText)), 1)
    ])
  ], 512)), [
    [_unref(vOnClickOutside), () => emits('close')]
  ])
}
}

})