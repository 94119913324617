import { companyAPI } from '@/core/api/company';
import { ordersAPI } from '@/apps/orders/api';

const state = {
  // Item
  items: [],
  searchItems: [],
  selectedToWriteOffItemsIDs: [],
  searchItemsString: '',
  selectedSearchItems: [],
  isSelectAllItems: false,

  // WriteOff
  writeOffs: [],
  writeOff: {},
  writeOffStatuses: {},

  // Order
  order: {}
};

const getters = {
  // Item
  items: state => state.items,
  searchItems: state => state.searchItems,
  selectedToWriteOffItemsIDs: state => state.selectedToWriteOffItemsIDs,
  searchItemsString: state => state.searchItemsString,
  selectedSearchItems: state => state.selectedSearchItems,
  isSelectAllItems: (state, getters) => (getters.items.length === getters.selectedToWriteOffItemsIDs.length) && !!getters.selectedToWriteOffItemsIDs.length,

  // WriteOff
  writeOffs: state => state.writeOffs,
  writeOff: state => state.writeOff,
  writeOffStatuses: state => state.writeOffStatuses,

  //Order
  order: state => state.order
};

const mutations = {
  // Item
  setItems: (state, payload) => (state.items = payload),
  setSearchItems: (state, payload) => {
    state.searchItems = [
      ...state.selectedSearchItems,
      ...payload.filter(item => {
          return !state.selectedSearchItems
            .map(selectedItem => selectedItem.id)
            .includes(item.id);
        }
      )
    ];
  },
  setSelectedToWriteOffItemsIDs: (state, selectedToWriteOffItemsIDs) => (state.selectedToWriteOffItemsIDs = selectedToWriteOffItemsIDs),
  setSearchItemsString: (state, searchItemsString) => (state.searchItemsString = searchItemsString),
  setSelectedSearchItems: (state, selectedSearchItems) => (state.selectedSearchItems = selectedSearchItems),
  setIsSelectAllItems: (state, isSelectAllItems) => (state.isSelectAllItems = isSelectAllItems),

  // WriteOff
  setWriteOffs: (state, payload) => state.writeOffs = payload,
  setWriteOff: (state, payload) => state.writeOff = payload,
  setWriteOffStatuses: (state, payload) => state.writeOffStatuses = payload,

  // Order
  setOrder: (state, payload) => state.order = payload
};

const actions = {
  // Item
  setItems: ({ commit }, items) => commit('setItems', items),
  setSearchItems: ({ commit }, searchItems) => commit('setSearchItems', searchItems),
  setSelectedToWriteOffItemsIDs: ({ commit }, selectedToWriteOffItemsIDs) => commit('setSelectedToWriteOffItemsIDs', selectedToWriteOffItemsIDs),
  setSearchItemsString: ({ commit }, searchItemsString) => commit('setSearchItemsString', searchItemsString),
  setSelectedSearchItems: ({ commit }, selectedSearchItems) => commit('setSelectedSearchItems', selectedSearchItems),
  setIsSelectedAllItems: async ({ commit }, isSelectAllItems) => commit('setIsSelectAllItems', isSelectAllItems),

  // WriteOff
  createWriteOff: ({ commit }, payload) => {
    const items = [];
    payload.items.map(item => {
      items.push({ item_id: item.item.id, quantity: item.quantity, price: item.price });
    });
    return companyAPI.createOrderWriteOff({
      items,
      payment_id: payload.order.id,
      store_id: payload.order.store_id,
      client_id: payload.order.customer_id,
      catalog_id: payload.order.store.catalog_id
    });
  },
  getWriteOffByID: ({ commit, rootGetters }, writeOffID) => {
    return companyAPI.getOrderWriteOffByID({
      charge_id: writeOffID,
      fields: rootGetters['orders$writeOffsTable/writeOffPositionTableFields'],
    })
      .then(res => {
        commit('setWriteOff', res.items[0]);
        return res.items[0];
      })
  },
  setWriteOff: ({ commit }, writeOff) => commit('setWriteOff', writeOff),
  getWriteOffsByOrderID: ({ commit, rootGetters }, orderID) => {
    return companyAPI.getOrderWriteOffList({
      filters: { payment_id: orderID },
      fields: rootGetters['orders$writeOffsTable/writeOffPositionTableFields'],
    })
      .then(res => {
        commit('setWriteOffs', res.items);
        return res.items;
      });
  },
  getWriteOffStatuses: ({ commit }) => {
    return companyAPI.getWriteOffStatuses().then(statuses => {
      commit('setWriteOffStatuses', statuses);
      return statuses;
    });
  },
  getWriteOffs: ({ commit }, params) => {
    return companyAPI.getOrderWriteOffList(params)
      .then(res => {
        commit('setWriteOffs', res.items)
        return res;
      });
  },
  clearWriteOffs: ({commit}) => {
    commit('setWriteOffs', []);
  },


  // Order
  getOrderById: ({commit}, orderID) => {
    return ordersAPI.getOrderById({id: orderID})
      .then(order => commit('setOrder', order));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

