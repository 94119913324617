import { createI18n } from 'vue-i18n';
import { utils } from '@brskl/core';

import { ru, en } from '@/core/locales/';

export const translateLocales = {
  ru: { id: 1, name: 'RU', label: 'ru', locale: 'ru', icon: 'ru', displayName: 'Русский' },
  en: { id: 7, name: 'EN', label: 'en', locale: 'en', icon: 'us', displayName: 'English' },
};

export const DEFAULT_CURRENCY_SYMBOL = '₽';
export const DEFAULT_CURRENCY_CODE = 'RUB';
export const DEFAULT_LOCALE = 'ru';

const locales = { ru, en };

const getLocale = () => {
  const preferredLocale = localStorage.getItem('TLocale');
  const browserLocale = navigator.language.slice(0, 2);
  const supportingLocales = Object.keys(locales);
  return [
    preferredLocale,
    browserLocale,
    DEFAULT_LOCALE,
  ].find((locale) => supportingLocales.includes(locale));
};

const instance = createI18n({
  legacy: true,
  globalInjection: true,
  silentTranslationWarn: true,
  locale: getLocale(),
  messages: locales,
  fallbackLocale: IS_DEV ? [] : [DEFAULT_LOCALE],
  numberFormats: {
    ru: {
      currency: {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    }
  },
  pluralizationRules: {
    ru(choice) {
      return utils.getSlavicPluralIndex(choice);
    },
  },
});

export const getI18n = () => instance.global;

export function i18n(app) {
  app.use(instance);
  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$i18n = instance.global;
}
