// --- INJECTED ---
import { getI18n } from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
    {
        label: i18n.t('misc.payments')
    },
    {
        label: i18n.t('common.write_offs'),
        route: { name: 'payments$writeOffs' }
    },
    {
        label: i18n.t('misc.payments_refund'),
        route: { name: 'orders$orders' },
    },
    {
        label: i18n.t('misc.payment_id'),
        route: { name: 'orders$info' },
    },
    {
        label: i18n.t('misc.return_processing'),
    },
    {
      label: i18n.t('misc.new_writeoff'),
    },
    {
      label: i18n.t('misc.write_off'),
      route: { name: 'payments$writeOff' },
    },
];

export default [
  {
    path: '/orders/write-offs',
    name: 'payments$writeOffs',
    component: () => import('@/apps/orders/views/Write-offs/WriteOffs'),
    meta: {
      title: i18n.t('common.write_offs'),
      breadCrumb: [breadCrumb[0], breadCrumb[1]]
    }
  },
  {
    path: '/orders/write-offs/:id',
    name: 'payments$writeOff',
    component: () => import('@/apps/orders/views/Write-offs/WriteOff/WriteOff.vue'),
    meta: {
      title: i18n.t('misc.new_writeoff'),
      breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[6]]
    },
  },
  {
    path: '/orders/write-offs/create/:order_id',
    name: 'payments$writeOffs$create',
    component: () => import('@/apps/orders/views/Write-offs/CreateWriteOff/CreateWriteOff'),
    meta: {
      title: i18n.t('misc.new_writeoff'),
      breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[5]]
    },
  },
  {
    path: '/orders',
    name: 'orders$orders',
    component: () => import('@/apps/orders/views/Orders'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('misc.payments'),
    },
  },
  {
    path: '/orders/:id',
    name: 'orders$order',
    component: () => import('@/apps/orders/views/Order/Order'),
    redirect: { name: 'orders$info' },
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2], breadCrumb[3]],
      title: i18n.t('common.payment'),
    },
    children: [
      {
        path: 'info',
        name: 'orders$info',
        component: () => import('@/apps/orders/views/Order/tabs/MainInfo/MainInfo'),
        meta: {
          title: i18n.t('misc.main_information'),
        },
      },
      {
        path: 'video',
        name: 'orders$video',
        // redirect: { name: 'orders$orders' } /* removed until frontend is done */,
        component: () => import('@/apps/orders/views/Order/tabs/Video/Video'),
        meta: {
          title: i18n.t('common.video'),
        },
      },
      {
        path: 'refunds',
        name: 'orders$refunds',
        component: () => import('@/apps/orders/views/Order/tabs/Refunds/Refunds'),
        meta: {
          title: i18n.t('misc.returns'),
        },
      },
      {
        path: 'events',
        name: 'orders$events',
        component: () => import('@/apps/orders/views/Order/tabs/Events/Events'),
        meta: {
          title: i18n.t('common.events'),
        },
      },
      {
        path: 'create-refund',
        name: 'orders$createRefund',
        component: () => import('@/apps/orders/views/Order/tabs/CreateRefund/CreateRefund'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2], breadCrumb[4]],
          title: i18n.t('misc.create_return'),
        },
      },
      {
        path: 'write-offs',
        name: 'orders$writeOffs',
        component: () => import('@/apps/orders/views/Order/tabs/WriteOffs/WriteOffs.vue'),
        meta: {
          title: i18n.t('common.write_offs'),
        },
      },

    ],
  },
];
