import { getI18n } from '@/core/i18n';

const i18n = getI18n();

export default [
  {
    title: i18n.t('misc.home'),
    route: { name: 'main' },
    icon: 'graph',
  },
  {
    title: i18n.t('common.trade_points'),
    route: { name: 'tradepoints$list' },
    icon: 'dot',
    groupIndex: 0,
  },
  {
    title: i18n.t('common.goods'),
    route: { name: 'items$items' },
    icon: 'food',
  },
  {
    title: i18n.t('common.menu'),
    route: { name: 'menus$menus' },
    icon: 'icon_list',
  },
  {
    title: i18n.t('misc.payments'),
    icon: 'barcode',
    groupIndex: 0,
    children: [
      {
        title: i18n.t('common.payments_refunds'),
        route: { name: 'orders$orders' },
      },
      {
        title: i18n.t('common.write_offs'),
        route: { name: 'payments$writeOffs' },
      }
    ]
  },
  {
    title: i18n.t('misc.reports'),
    route: { name: 'reports$list' },
    icon: 'icon_flag',
  },
  {
    title: i18n.t('misc.telemetry'),
    route: { name: 'equipment$equipment' },
    icon: 'icon_telemetry',
  },
  {
    title: i18n.t('misc.warehouse'),
    icon: 'icon_storage',
    children: [
      {
        title: i18n.t('misc.catalogs_categories'),
        route: { name: 'catalogs-categories$catalogs-categories' },
      },
      {
        title: i18n.t('misc.stock'),
        route: { name: 'remains$remains' },
      },
      {
        title: i18n.t('common.supplies'),
        route: { name: 'supply$supply' },
      },
      {
        title: i18n.t('common.movements'),
        route: { name: 'transfers$transfers' },
      },
      {
        title: i18n.t('common.write_offs'),
        route: { name: 'write-offs$write-offs' },
      },
      {
        title: i18n.t('misc.movement_reports'),
        route: { name: 'storage-reports$storage-reports' },
      },
    ],
  },
  {
    title: i18n.t('misc.marketing'),
    icon: 'icon_percent',
    children: [
      {
        title: i18n.t('common.discounts'),
        route: { name: 'discounts$discounts' },
      },
      {
        title: i18n.t('misc.terminal_advert'),
        route: { name: 'terminal-promo$terminal-promo' },
      },
    ],
  },
  {
    title: i18n.t('common.service_center'),
    icon: 'service',
    children: [
      {
        title: i18n.t('misc.diagnostics'),
        route: { name: 'service-diagnostics$diagnostics' },
      },
    ],
  },
  {
    title: i18n.t('misc.charges'),
    key: 'payments',
    icon: 'icon_cash',
    children: [
      {
        title: i18n.t('misc.acquiring'),
        route: { name: 'shops$list' },
      },
      {
        title: i18n.t('misc.operations'),
        route: { name: 'operations$list' },
      },
      {
        title: i18n.t('misc.accounts'),
        route: { name: 'bills$list' },
      },
      {
        title: i18n.t('misc.compensations'),
        route: { name: 'refunds$list' },
      },
    ],
  },
  {
    title: i18n.t('common.documents'),
    route: { name: 'documents$documents' },
    icon: 'icon_docs',
  },
  {
    title: i18n.t('misc.briskly_catalog'),
    route: { name: 'devices$buy' },
    icon: 'icon_chip',
  },
  {
    title: i18n.t('misc.marketplace'),
    route: { name: 'marketplace' },
    icon: 'store',
  },
  {
    title: i18n.t('common.settings'),
    icon: 'gear',
    children: [
      {
        title: i18n.t('common.legal_entities'),
        route: { name: 'entities$list' },
        init_key: 'entities',
      },
      {
        title: i18n.t('common.virtual_terminals'),
        route: { name: 'terminals$list' },
        init_key: 'terminals',
      },
      {
        title: i18n.t('common.employees'),
        route: { name: 'employees$employees' },
      },
      {
        title: i18n.t('misc.user_profile'),
        route: { name: 'profile$profile' },
      },
    ],
  },
  {
    title: i18n.t('misc.questions_answers'),
    route: { name: 'help$faq' },
    icon: 'icon_help',
  },
];

