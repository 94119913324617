import enCommon from '@/core/locales/en_common';
import enMisc from '@/core/locales/en_misc';

export const en = {
  briskly_requisites: 'Briskly requisites',
  main_data: 'Main information',
  unit_796: 'Piece',
  unit_657: 'Item',
  unit_111: 'Milliliter',
  unit_112: 'Liter',
  unit_163: 'Gram',
  unit_625: 'Sheet',
  unit_704: 'Set',
  unit_715: 'Pair',
  unit_166: 'Kilogram',
  unit_736: 'Roll',
  unit_778: 'Package',
  documents_tabs_docs: 'Documents',
  documents_tabs_acts: 'Acts',
  documents_tabs_bills: 'Bills',
  documents_tabs_payment: 'Payment',
  documents_requisites: 'Requisites',
  documents_type: 'Type',
  documents_added: 'Added',
  documents_is_signed: 'Signed',
  documents_document_fallback_name: 'No name',
  documents_document_fallback_type: 'No type',
  documents_document_fallback_id: 'No id',
  documents_document_fallback_requisites: 'No requisites',
  Invoice: 'Invoice',
  UpdStatus1: 'Universal transfer document',
  ActForTheReportingPeriod1: 'Act for the reporting period',
  ActForTheReportingPeriod2: 'Settlement statement for the reporting period',
  OrdersReport1: 'Sales report',
  BYN: 'Belarusian ruble (BYN)',
  USD: 'US dollar (USD)',
  EUR: 'Euro (EUR)',
  KZT: 'Kazakh tenge (KZT)',
  CAD: 'Canadian dollar (CAD)',
  CNY: 'Chinese yuan (CNY)',
  RUB: 'Russian ruble (RUB)',
  TRY: 'Turkish lira (TRY)',
  UAH: 'Ukrainian hryvnia (UAH)',
  GBP: 'Pound Sterling (GBP)',
  CHF: 'Swiss franc (CHF)',
  HRK: 'Croatian kuna (HRK)',
  AED: 'Emirati dirham (AED)',
  BYN_CODE: '₽',
  USD_CODE: '$',
  EUR_CODE: '€',
  KZT_CODE: '₸',
  CAD_CODE: '$',
  CNY_CODE: '¥',
  RUB_CODE: '₽',
  TRY_CODE: '₺',
  UAH_CODE: '₴',
  GBP_CODE: '£',
  CHF_CODE: '₣',
  HRK_CODE: 'kn',
  AED_CODE: 'DH',
  datetimeCreatedAt: 'Creation date and time',
  customer: 'Customer',
  status: 'Status',
  store: 'Supermarket',
  refrigerator: 'Micromarket',
  store_online: 'Online store',
  cafe: 'Pre-order',
  production_with_delivery: 'Production with delivery',
  filling_station: 'Gas station',
  cigarettes: 'Cigarettes',
  coffee_machine: 'Coffee machine',
  address: 'Address',
  phone: 'Phone',
  active_status: 'Awaiting payment',
  paid_status: 'Paid',
  refund_status: 'Returned',
  refund_awaiting_status: 'Return expected',
  refund_error_status: 'Return error',
  refund_partial_status: 'Partially refunded',
  removed_status: 'Removed',
  approved_status: "Approved",
  cancelled_status: "Denied",
  created_status: "Being reviewed",
  declined_status: "Declined",
  delete_review_status: "Request cancelled",
  review_status: "Under review",
  order_additional_status_created: "write-off being reviewed",
  order_additional_status_success: "write-off successful",
  order_additional_status_error: "write-off failed",
  order_additional_status_cancelled: "write-off denied",
  avg_amount: 'Average bill',
  income: 'Income',
  quantity: 'Number of purchases',
  total_amount: 'Revenue',
  total_customers: 'Customers',
  total_count: 'Goods',
  extended_type: 'Type',
  extended_types_store: 'Store',
  extended_types_refrigerator: 'Micromarket',
  extended_types_store_online: 'Online store',
  extended_types_cafe: 'Cafe',
  extended_types_production_with_delivery: 'Delivery',
  extended_types_filling_station: 'Gas station',
  extended_types_cigarettes: 'Cigarettes',
  extended_types_coffee_machine: 'Coffee machine',
  extended_types_module: 'Module',
  store_count: 'Attached stores',
  complete_registration: 'To start using your Personal Account please sign up.',
  your_company_country: 'Country of your company',
  register: 'Sign up',
  your_company_inn: 'TIN of your company',
  your_company_number: 'Your company registration number',
  requisites_status_pending: 'Checked',
  requisites_status_active: 'Active',
  requisites_status_uncompleted: 'Not filled',
  completed: 'Completed',
  opened: 'Opened',
  import_excel_file: 'Upload .xlsx file',
  one_s: '1C',
  iiko: 'Айко',
  iiko_biz: 'Айко Биз',
  yml: 'YML',
  moy_sklad: 'Мой Склад',
  supermag: 'Супермаг',
  mts_kassa: 'МТС Касса',
  store_house: 'StoreHouse',
  poster: 'Poster',
  ya_badge: 'Яндекс.Бейдж',
  article: 'Vendor code',
  name: 'Name',
  price: 'Price',
  barcode: 'Barcode',
  unit_id: 'ОКЕИ (Ед. измерения)',
  volume: 'Volume',
  category: 'Category',
  catalog_name: 'Catalog',
  Success_notification: 'Success!',
  Error_notification: 'Error!',
  temperature: 'Temperature change notifications',
  connection: 'Connection status notifications',
  device_type_camera: 'Camera',
  device_type_coffee_machine: 'Coffee machine',
  device_type_microwave_oven: 'Microwave',
  device_type_module: 'Module',
  device_type_terminal: 'Terminal',
  tax_system_envd: 'ЕНВД',
  tax_system_esn: 'ЕСХН',
  tax_system_npd: 'НПД',
  tax_system_general: 'ОСН',
  tax_system_patent: 'Patent',
  tax_system_simplified_1: 'УСН (доходы ставка 6%)',
  tax_system_simplified_2: 'УСН (доходы - расходы ставка 15%)',
  role_admin: 'Administrator',
  role_checker: 'Checker',
  role_courier: 'Courier',
  roles: 'Roles',
  error_1000: 'Incorrect link',
  error_1010: 'Incorrect link',
  error_1020: 'Incorrect link',
  error_1030: 'Incorrect link',
  error_1040: 'Incorrect link',
  error_1050: 'Incorrect link',
  components_list: 'List',
  report_type_order_stats: 'Order statistics',
  report_type_sale_stats: 'Sale statistics',
  report_type_item_stats: 'Item statistics',
  report_type_customer_stats: 'Customer statistics',
  report_type_sales_report: 'Sales report',
  report_type_promocodes_report: 'Promocodes report',
  report_type_deliveries_report: 'Deliveries report',
  reports_columns_item_price: 'Item price',
  tradePoint_status_active: 'Active',
  filters: 'Filters',
  unit: 'unit',
  undefined_CODE: '',
  reports_columns_amount: 'Amount with discount',
  reports_columns_base_amount: 'Sum',
  reports_columns_commission: 'Commission',
  reports_columns_count: 'Quantity',
  reports_columns_customer_count: 'Number of clients',
  reports_columns_customers_count: 'Number of clients',
  reports_columns_customer_id: 'Client ID',
  reports_columns_delivery_price_to_pay: 'Delivery price to pay',
  reports_columns_delivery_price: 'Delivery price',
  reports_columns_delivery_progress_status: 'Delivery status',
  reports_columns_external_service: 'Delivery type',
  reports_columns_hash_id: 'Order №',
  reports_columns_income: 'Income',
  reports_columns_item_barcode: 'Item barcode',
  reports_columns_barcode: 'Item barcode',
  reports_columns_item_count: 'Item quantity',
  reports_columns_item_hash_id: 'Item ID',
  reports_columns_hash: 'Item ID',
  reports_columns_item_name: 'Item name',
  reports_columns_net_amount: 'Net amount',
  reports_columns_payment_method: 'Payment method',
  reports_columns_payment_service: 'Payment type',
  reports_columns_period: 'Day',
  reports_columns_promo_code: 'Promo code',
  reports_columns_quantity: 'Item quantity',
  reports_columns_refund_amount: 'Refund',
  reports_columns_status_refund: 'Refund status',
  reports_columns_status: 'Order status',
  reports_columns_store_id: 'Store ID',
  reports_columns_timestamp_inserting: 'Order time',
  reports_columns_total_amount: 'Total amount',
  reports_columns_total_base_amount: 'Base amount',
  reports_columns_price: 'Price',
  reports_columns_sum_amount: 'Sales',
  reports_columns_order_id: 'Order ID',
  reports_columns_vat_modes: 'VAT rates',
  reports_columns_vat_amount: 'VAT amount',
  reports_columns_item_orders: 'Orders',
  reports_columns_id: 'ID',
  total_name: 'Total',
  all: 'All',
  active: 'Active',
  inactive: 'Inactive',
  carts_employee_fio: 'Employee full name',
  carts_period_start: 'Start date',
  carts_period_end: 'End date',
  carts_link_status: 'Link status',
  carts_link: 'Link',
  carts_delete_link: ' ',
  carts_active: 'Active',
  carts_inactive: 'Canceled',
  carts_on_hold: 'On hold',
  carts_paid: 'Paid',
  carts_removed: 'Canceled',
  carts_link_active: 'Active',
  carts_link_inactive: 'Inactive',
  carts_created: 'Created:',
  carts_timer: 'Collecting:',
  carts_was_assembled: 'Was collecting',
  carts_items: 'Items:',
  carts_total_summ: 'Total amount:',
  options_grouping_DAYS: 'By days',
  options_grouping_WEEKS: 'By weeks',
  options_grouping_DAYS_OF_WEEK_AND_HOURS: 'By days of week and hours',
  options_grouping_DAYS_OF_WEEK: 'By days of week',
  options_grouping_HOURS: 'By hours',
  options_grouping_MONTHS: 'By months',
  options_grouping_YEARS: 'By years',
  options_grouping_NONE: 'None',
  options_payment_service_APP: 'Via app',
  options_payment_service_TERMINAL: 'Via terminal',
  options_payment_service_YANDEX_BADGE: 'Via Yandex Badge',
  options_type_BASE: 'Base',
  options_type_INCREASE: 'Increase',
  options_type_DECREASE: 'Decrease',
  options_status_PAID: 'Paid',
  options_status_PROCESSED: 'Processed',
  options_status_REFUND: 'Refund',
  options_status_REFUND_AWAITING: 'Refund processed',
  options_status_REFUND_ERROR: 'Refund error',
  options_status_REFUND_PARTIAL: 'Partial refund',
  options_status_ACTIVE: 'Active',
  options_status_REMOVED: 'Removed',
  active_BAR: 'Active carts',
  on_hold_BAR: 'Carts on hold',
  removed_BAR: 'Canceled carts',
  options_dostavista: 'Dostavista',
  options_gett: 'Gett',
  MONTH_01: 'January',
  MONTH_02: 'February',
  MONTH_03: 'March',
  MONTH_04: 'April',
  MONTH_05: 'May',
  MONTH_06: 'June',
  MONTH_07: 'July',
  MONTH_08: 'August',
  MONTH_09: 'September',
  MONTH_10: 'October',
  MONTH_11: 'November',
  MONTH_12: 'December',
  WEEKDAY_01: 'Monday',
  WEEKDAY_02: 'Tuesday',
  WEEKDAY_03: 'Wednesday',
  WEEKDAY_04: 'Thursday',
  WEEKDAY_05: 'Friday',
  WEEKDAY_06: 'Saturday',
  WEEKDAY_07: 'Sunday',
  event_store_id: 'Store ID',
  event_store_name: 'Store name',
  event_created_at: 'Creation time',
  event_status: 'Status',
  event_customer_id: 'Customer ID',
  event_create: 'Creation',
  event_delete_position: 'Delete position',
  event_increase_position: 'Increase position',
  event_decrease_position: 'Decrease position',
  event_create_position: 'Add item',
  event_delete: 'Delete item',
  event_refund: 'Refund',
  not_found_text: 'Oops! Something went wrong...',
  back_to_home: 'Back to home',
  support_service_greeting: 'Hi! How can we help?',
  week: 'Week',
  month: 'Month',
  three_months: '3 Months',
  six_months: '6 Months',
  sum_orders_amount: 'By profit',
  sum_orders_count: 'By orders',
  sum_items_count: 'By items',
  is_only_my_store: 'My markets',
  period: 'Range',
  main_rate: 'Main rate',
  region: 'Region',
  currency: 'Currency',
  vat_rate: 'VAT rate (%)',
  brisklyModuleActivated: 'Briskly module № {0} is activated and ready for use',

  ...enCommon,
  ...enMisc,
};
