// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('misc.home'),
    route: { name: 'main' },
  },
  {
    label: i18n.t('misc.whats_new'),
    route: { name: 'main$patchnotes' },
  },
  {
    label: "",
    route: { name: 'main$patchnotes$patchnote' },
  },
  {
    label: i18n.t('misc.rework_request'),
    route: { name: 'rework' },
  },
];

export default [
  {
    path: '/home',
    name: 'main',
    component: () => import('@/apps/main/views/Main'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('misc.home'),
    },
    children: [
      {
        path: '/rework',
        name: 'rework',
        component: () => import('@/apps/main/views/Rework/Rework.vue'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3]],
          title: i18n.t('misc.rework_request'),
        },
      }
    ]
  },
  {
    path: '/patchnotes',
    name: 'main$patchnotes',
    component: () => import('@/apps/main/views/Patchnotes/Patchnotes'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('misc.updates'),
    },
  },
  {
    path: '/patchnotes/:id',
    name: 'main$patchnotes$patchnote',
    component: () => import('@/apps/main/views/PatchNote/PatchNote.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[2]],
    },
  },
];
