import orders from './store/index';
import store from '@/core/store';
import writeOff from '@/apps/orders/store/writeOff';
import writeOffTable from '@/apps/orders/store/writeOffTable';

export const connect = (opts = {}) => {
  store.registerModule('orders$orders', orders);
  store.registerModule('orders$writeOffs', writeOff)
  store.registerModule('orders$writeOffsTable', writeOffTable)
};
