// --- INJECTED ---
import {getI18n} from '@/core/i18n';
import { companyAPI } from '@/core/api/company';

const i18n = getI18n();
// --- /INJECTED ---


const state = {

// Items
  searchItemsTableColumns: [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'id',
      label: 'ID',
      removable: false,
    },
    {
      name: 'name',
      label: i18n.t('common.name'),
      removable: false,
    },
    {
      name: 'barcode',
      label: i18n.t('common.barcode'),
      removable: false,
    },
    {
      name: 'price',
      label: i18n.t('common.price'),
      removable: false,
    },
  ],

  itemsTableColumns: [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: i18n.t('common.product_name'),
    },
    {
      name: 'barcode',
      label: i18n.t('common.good_barcode'),
    },
    {
      name: 'quantity',
      label: i18n.t('common.quantity'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price_base',
      label: i18n.t('common.price'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'amount_base',
      label: i18n.t('common.amount'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price_discount',
      label: i18n.t('common.discount'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price',
      label: i18n.t('misc.total_price'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'amount',
      label: i18n.t('common.total_amount'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
  ],

  searchItemsTableFields: {
    id: 'id',
    name: 'name',
    barcode: 'barcode',
    price: 'price',
  },

  searchItemsTableFilters: {
    catalog_id: [],
    '!catalog.item_type': ['coffee'],
  },


  // Write-Offs
  writeOffPositionTableColumns: [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: i18n.t('common.product_name'),
    },
    {
      name: 'barcode',
      label: i18n.t('common.good_barcode'),
    },
    {
      name: 'quantity',
      label: i18n.t('common.quantity'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price',
      label: i18n.t('common.price'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price_discount',
      label: i18n.t('common.discount'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'total_price',
      label: i18n.t('misc.total_price'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'amount',
      label: i18n.t('common.amount'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
  ],

  writeOffPositionTableFields: {
    id: 'id',
    payment_id: 'payment_id',
    client_id: 'client_id',
    timestamp_inserting: 'timestamp_inserting',
    store_name: 'store_name',
    items: 'items',
    status: 'status'
  },

  writeOffTableColumns: [
    {
      name: 'id',
      label: i18n.t('misc.write_off_id'),
      enableSorting: true,
    },
    {
      name: 'payment_id',
      label: i18n.t('misc.basic_pay'),
      enableSorting: true,
    },
    {
      name: 'client_id',
      label: i18n.t('common.client_id'),
      enableSorting: true,
    },
    {
      name: 'timestamp_inserting',
      label: i18n.t('common.creation_time'),

    },
    {
      name: 'amount',
      label: i18n.t('common.amount') + ', ' + i18n.t(`RUB_CODE`),

    },
    {
      name: 'status',
      label: i18n.t('common.status'),
    },
    {
      name: 'support_comments',
      label: i18n.t('misc.comments'),
    },
  ],

  writeOffTableFields: {
    id: 'id',
    payment_id: 'payment_id',
    client_id: 'client_id',
    timestamp_inserting: 'timestamp_inserting',
    summary: 'summary',
    items: 'items',
    status: 'status',
    support_comments: 'support_comments',
  },
  writeOffTableSorting: {
    id: 'DESC',
    payment_id: '',
    client_id: ''
  },
  writeOffTableFilters: {
    store_id: [],
    status: [],
    client_id: [],
    timestamp_inserting_start: '',
    timestamp_inserting_end: ''
  },

  writeOffTableFilterOptions: [
    {
      name: 'store_id',
      label: i18n.t('common.trade_points'),
      type: 'multiselect',
      inputPlaceholder: i18n.t('common.trade_point_id_name'),
      remoteMethod: companyAPI.getStoreListV1,
    },
    {
      name: 'status',
      label: i18n.t('common.order_status'),
      type: 'checkbox',
      options: [],
    },
    {
      name: 'client_id',
      label: i18n.t('common.client_id'),
      type: 'input',
      inputPlaceholder: i18n.t('common.client_id'),
      inputType: 'number',
      options: [],
    },
    {
      name: 'timestamp_inserting',
      isSpecial: true,
      label: i18n.t('common.creation_date'),
      type: 'date',
      dateFormat: 'yyyy-MM-dd',
      options: [],
      filterStart: 'timestamp_inserting_start',
      filterEnd: 'timestamp_inserting_end',
    },
  ],

  writeOffTablePagination: {
    totalItems: 0,
    totalPages: 1,
    page: 1,
    limit: 10,
  }
};

const getters = {
  searchItemsTableColumns: state => state.searchItemsTableColumns,
  searchItemsTableFields: state => state.searchItemsTableFields,
  searchItemsTableFilters: state => state.searchItemsTableFilters,
  itemsTableColumns: state => state.itemsTableColumns,
  writeOffPositionTableColumns: state => state.writeOffPositionTableColumns,
  writeOffPositionTableFields: state => state.writeOffPositionTableFields,
  writeOffTableColumns: state => state.writeOffTableColumns,
  writeOffTableFields: state => state.writeOffTableFields,
  writeOffTableSorting: state => state.writeOffTableSorting,
  writeOffTableFilters: state => state.writeOffTableFilters,
  writeOffTableFilterOptions: (state, getters, rootGetters) => {
    let statuses = rootGetters['orders$writeOffs'].writeOffStatuses;
    let statusOptionIndex = state.writeOffTableFilterOptions.findIndex(option => option.name === 'status');
    state.writeOffTableFilterOptions[statusOptionIndex].options = Object.entries(statuses).map(([key, value]) => ({
      name: i18n.t(value),
      value: key
    }));
    return state.writeOffTableFilterOptions;
  },
  writeOffTablePagination: state => state.writeOffTablePagination,
};

const mutations = {
  setIsItemsTableColumnsCheckboxVisible: (state, isCheckboxVisible) => {
    let issetCheckbox = !!state.itemsTableColumns.filter(column => column.name === 'checkbox').length;
    if (isCheckboxVisible && !issetCheckbox) {
      state.itemsTableColumns.unshift({
        name: 'checkbox',
        disableClickEvent: true,
      });
    }
    if (!isCheckboxVisible && issetCheckbox) {
      state.itemsTableColumns = state.itemsTableColumns.filter(column => column.name !== 'checkbox');
    }
  },
  setFilters: (state, filters) => {
    state.writeOffTableFilters = filters;
  }
};

const actions = {
  setIsItemsTableColumnsCheckboxVisible: ({ commit }, isCheckboxVisible) => commit('setIsItemsTableColumnsCheckboxVisible', isCheckboxVisible),
  setFilters: ({commit}, filters) => commit('setFilters', filters)
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

