export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "message_remove_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить это сообщение?"])},
        "message_network_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет подключения к интернету. Повторить отправку сообщения?"])},
        "retry_message_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить"])}
      },
      "en": {
        "message_remove_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this message?"])},
        "message_network_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No internet connection. Retry sending the message?"])},
        "retry_message_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])}
      }
    }
  })
}
