import { api } from '@brskl/core';

class OrdersAPI {
  getOrdersList = (params = {}) => {
    const path = 'dashboard/order/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  generateOrdersReport = (params = {}) => {
    const path = 'dashboard/order/generate-report';
    return api.endpoints.business.company.get({ path, params });
  };

  getOrderById = (params = {}) => {
    const path = 'dashboard/order/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };

  createRefund = (params = {}) => {
    const path = 'dashboard/order/refund/create';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  acceptRefund = (params = {}) => {
    const path = 'dashboard/order/refund/refund';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  getStatusesDictionary = () => {
    const path = 'dashboard/order/get-statuses';
    return api.endpoints.business.company.get({ path });
  };

  verifyOrder = (params = {}) => {
    const path = 'dashboard/order/update-verification-status';
    return api.endpoints.business.companyV2.post({ path, params });
  };
}

export const ordersAPI = new OrdersAPI();
