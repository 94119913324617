<template>
  <div v-if="profile">
    <MenuItem
      v-for="(item, index) in filteredMenu.first"
      :key="`first_${index}`"
      :item="item"
      @expand="deviceIs !== 'desktop' ? setSidebarCollapsed(true) : null"
      @hide-menu="hideMenu" />
  </div>
  <hr class="divider"/>
  <div>
    <MenuItem
      v-for="(item, index) in filteredMenu.second"
      :key="`second_${index}`"
      :item="item"
      @hide-menu="hideMenu" 
      @expand="deviceIs !== 'desktop' ? setSidebarCollapsed(true) : null"
      />
      
  </div>
</template>

<script>
import menu from '@/core/data/menu';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { hideShopFor } from './data';
import MenuItem from './MenuItem';

export default {
  components: {
    MenuItem,
  },
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  emits: ['toggleMenu', 'openMenu'],
  data() {
    return {
      showSubmenu: false,
      menu,
      filteredMenu: {
        first: [],
        second: [],
      },
      second: [],
    };
  },
  computed: {
    ...mapGetters({
      isFeatureFlag: 'core$employeePreferences/isFeatureFlag',
      companyId: 'core$employeePreferences/companyId',
      isNewUser: 'main$main/isNewUser',
      company: 'core$company/company',
      deviceIs: 'core$browser/deviceIs',
      sidebarCollapsed: 'core$menu/sidebarCollapsed',
    }),
  },
  watch: {
    isNewUser() {
      this.init();
    },
  },
  async created() {
    try {
      await this.getEmployeePreferences();
    } catch (e) {
      console.error(e);
    }
    if (!this.isFeatureFlag) {
      this.menu = this.menu.filter((item) => item.key !== 'payments');
    }
    if (hideShopFor.includes(this.companyId)) this.menu = this.menu.filter((el) => el?.route?.name !== 'devices$buy');
    if (Object.keys(this.profile).length) this.init();
    this.$watch(
      () => this.profile,
      (value) => {
        if (Object.keys(value).length) this.init();
      },
    );
  },
  methods: {
    ...mapMutations({
      setSidebarCollapsed: 'core$menu/setSidebarCollapsed',
    }),
    ...mapActions({
      getEmployeePreferences: 'core$employeePreferences/getEmployeePreferences',
    }),
    toggleSubMenu(index) {
      if (this.showSubmenu === index) {
        this.showSubmenu = '';
      } else {
        this.showSubmenu = index;
        this.$emit('openMenu');
      }
    },
    hideMenu() {
      this.setSidebarCollapsed(false);
    },
    init() {
      this.filteredMenu.first = this.menu.filter((item) => {
        if (item.groupIndex === 0) return true;
        if (!item.children && !this.isNewUser) return true;
        return false;
      });

      this.second = this.menu.filter((item) => !this.filteredMenu.first.includes(item));

      this.filteredMenu.second = this.second.map((el) => {
        if (!!el.children) {
          el.children = el.children.filter((route) => {
            route.title = this.$t(route.title);
            if (!!route.init_key) {
              // FIX: use country_code from company instead user profile company
              // this.profile.company.country_code === 'RU'
              return this.company?.country_code === 'RU' ? route.init_key === 'entities' : route.init_key === 'terminals';
            }
            return route;
          });
          return el;
        }
        return el;
      });

      this.toggleSubMenu(
        // Боже храни javascript
        this.filteredMenu.second.indexOf(
          this.filteredMenu.second.find((el) =>
            el.children.find((inner) => inner.route.name.split('$')[0] === this.$route.name.split('$')[0]),
          ),
        ),
      );
    },

    onMenuComponentUpdate() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  display: block;
  height: 1px;
  border: 0;
  margin: 1.5rem 0;
  border-top: solid 1px #e9ebf3;

  &:first-child,
  &:last-child {
    display: none;
  }
}
</style>
