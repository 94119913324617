import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b19f93e0")
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["data-collapsed", "data-device"]
const _hoisted_3 = { class: "children" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuLink = _resolveComponent("MenuLink")!
  const _component_TransitionCollapse = _resolveComponent("TransitionCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['item', { '_expandable': $options.hasChildren, '_expanded': $data.isExpanded }]),
      "data-collapsed": _ctx.sidebarCollapsed,
      "data-device": _ctx.deviceIs,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onRootItemClick && $options.onRootItemClick(...args)))
    }, [
      _createVNode(_component_MenuLink, {
        item: $props.item,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide-menu')))
      }, null, 8, ["item"])
    ], 10, _hoisted_2),
    ($options.hasChildren)
      ? (_openBlock(), _createBlock(_component_TransitionCollapse, { key: 0 }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.children, (child, index) => {
                return (_openBlock(), _createBlock(_component_MenuLink, {
                  key: index,
                  item: child,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('hide-menu')))
                }, null, 8, ["item"]))
              }), 128))
            ], 512), [
              [_vShow, $data.isExpanded && (_ctx.sidebarCollapsed || _ctx.deviceIs === 'desktop')]
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}