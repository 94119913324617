// --- INJECTED ---
import { DEFAULT_CURRENCY_CODE, getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { companyAPI } from '@/core/api/company';
import router from '@/core/router';

const state = {
  storeInfo: null,
};

const getters = {
  storeInfoGetter: (state) => state.storeInfo,
};

const actions = {
  setStoreInfo: async ({ commit }, payload) => {
    commit('_setStoreInfo', payload);
  },
  createStore: async ({ state, dispatch }, payloadForCoffeeMachine) => {
    try {
      const createdStoreGroup = await companyAPI.createStoreGroup({ ...state.storeInfo });
      if (payloadForCoffeeMachine) {
        const catalog = await companyAPI.createCatalog({
          name: state.storeInfo.name,
          currency_code: DEFAULT_CURRENCY_CODE,
          item_type: 'coffee',
          coffee_machine_type: payloadForCoffeeMachine.coffee_machine_type || 'kaffit',
        });
        const createdCoffeeMachine = await companyAPI.createStoreCoffee({
          name: state.storeInfo.name,
          external_id: payloadForCoffeeMachine.external_id,
          address: state.storeInfo.address,
          catalog_id: catalog.id,
          address_floor: state.storeInfo.address_floor ? state.storeInfo.address_floor : null,
          is_closed_area: state.storeInfo.is_closed_area ? state.storeInfo.is_closed_area : null,
          min_distance_to_open: state.storeInfo.min_distance_to_open ? state.storeInfo.min_distance_to_open : null,
          coffee_machine_type: payloadForCoffeeMachine.coffee_machine_type || 'kaffit',
        });
        dispatch(
          'core$notifications/add',
          { message: i18n.t('common.creation_successful'), type: NoticeEnumTypes.success, source: '', title: 'Success' },
          { root: true },
        );
        state.storeInfo = null
        router.push({ name: 'tradepoints$detail', params: { id: createdCoffeeMachine.id } });
      } else {
        const createdStore = await companyAPI.createStore({
          ...state.storeInfo,
          store_group_id: createdStoreGroup.id,
          is_commodity_accounting_enabled: 1,
        });

        dispatch(
          'core$notifications/add',
          { message: i18n.t('common.creation_successful'), type: NoticeEnumTypes.success, source: '', title: 'Success' },
          { root: true },
        );
        state.storeInfo = null

        router.push({ name: 'tradepoints$detail', params: { id: createdStore.id } });
      }
    } catch (error) {
      console.log(error);
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'createStore', title: 'Error' },
        { root: true },
      );
    }
  },
};

const mutations = {
  _setStoreInfo: (state, payload) => (state.storeInfo = { ...state.storeInfo, ...payload }),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
