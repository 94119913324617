let id = 1;

const state = {
  items: [],
  shouldOpenChat: false,
};

const getters = {
  items: (state) => state.items,
  shouldOpenChat: (state) => state.shouldOpenChat,
};

const actions = {
  add: ({ commit }, data) => {
    commit('_add', { id: id++, ...data });
  },

  remove: ({ commit }, id) => {
    commit('_remove', id);
  },

  removeAll: ({ commit }) => {
    commit('_removeAll');
  },
  setChat: ({ commit }, payload) => {
    commit('_setChat', payload);
  },
};

const mutations = {
  _add: (state, value) => {
    state.items.push(value);
  },

  _remove: (state, value) => {
    state.items = state.items.filter((v) => v.id !== value);
  },

  _removeAll: (state) => {
    state.items = [];
  },

  _setChat: (state, payload) => {
    state.shouldOpenChat = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
