<template>
  <div ref="chat" class="chat">
    <div class="u-flex u-flex--col">
      <div class="response">
        <ChatAdminMessageHeader/>
        <p class="response__text">{{ chat_message.text }}</p>
      </div>
    </div>
    <div class="messages-container">
      <div v-for="(group, key) in groupedMessages" :key="key" ref="messagesWrapper" class="u-flex u-flex--col message-box">
        <Chip :text="formatChipToDayMonth(group.date)" class="chip" />
        <div v-for="(message, key) in group.messages" :key="key" :data-message-id="message.id">
          <div v-if="message.sender.alias === 'administrator'" class="u-flex u-flex--col">
            <div
              v-touch:hold="($event) => onAdminMessageRightClick($event, message)"
              class="response"
              @contextmenu="onAdminMessageRightClick($event, message)">
              <div v-if="message.reply_to_id" class="replied-message">
                <ChatAdminMessageHeader/>
                <span>{{ message.replied_message.text }}</span>
              </div>
              <ChatAdminMessageHeader/>
              <p class="response__text">{{ message.text }}</p>
              <div v-if="message.files.length > 0">
                <ChatFilePreview
                  v-for="file in message.files"
                  :key="file.id"
                  :file="file" />
              </div>
              <div class="u-flex u-ai-center request__container">
                <span v-if="message.created_at.substr(-8) !== message.updated_at.substr(-8)" style="font-size: 12px;">изменено</span>

                <Icon v-if="message.is_read" name="icon_isread" class="request__isread" />
                <Icon v-else name="icon_send" class="request__isread" />

                <p class="request__date">{{ formatDateToTime(message.created_at) }}</p>
              </div>
            </div>
          </div>
          <div v-else class="u-flex u-flex--col">
            <div
              v-touch:hold="($event) => onMessageRightClick($event, message)"
              class="request"
              @contextmenu="onMessageRightClick($event, message)">
              <div v-if="message.reply_to_id" class="replied-message">
                <strong v-if="message.replied_message?.sender_object_id == message.sender?.id">Вы</strong>
                <ChatAdminMessageHeader v-else/>
                <span>{{ message.replied_message.text }}</span>
                <!-- <span class="italic" v-else>Сообщение удалено</span> -->
              </div>
              <p class="request__text">{{ message.text }}</p>

              <div v-if="message.files.length > 0">
                <ChatFilePreview
                  v-for="file in message.files"
                  :key="file.id"
                  :file="file" />
              </div>

              <div class="u-flex u-ai-center request__container">
                <span v-if="message.created_at.substr(-8) !== message.updated_at.substr(-8)" style="font-size: 12px;">изменено</span>

                <Icon v-if="!message.is_read" name="icon_send" class="request__isread" />
                <Icon v-else name="icon_isread" class="request__isread" />

                <p class="request__date">{{ formatDateToTime(message.created_at) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="end" class="end"></div>
  </div>
  <ContextMenu ref="menu" :model="contextMenuOptions" />
  <ContextMenu ref="replyMenu" :model="replyMenuOption" />
  <ConfirmPopup>
    <template #message="slotProps">
      <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 p-4 mb-4 pb-0">
        <i :class="slotProps.message.icon" class="text-6xl text-primary-500"></i>
        <p>{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>
  <Transition>
    <ChatConfirmPopup
      v-if="isMessageRemovingConfirmVisible"
      :style="{ position: floatPosition }"
      :text="$t('message_remove_confirm_text')"
      @close="onMessageRemoveCancel"
      @confirm="onMessageRemoveConfirm"/>
  </Transition>
  <Transition>
    <ChatConfirmPopup
      v-if="isMessageRetrySendConfirmVisible"
      :style="{ position: floatPosition }"
      :text="$t('message_network_error')"
      :confirm-text="$t('retry_message_send')"
      @close="isMessageRetrySendConfirmVisible = false"
      @confirm="onSendMessage"/>
  </Transition>
  <div v-if="quoteMessageId" class="edit-container">
    <IconReply class="edit-container__icon" />
    <div style="justify-content: space-between; display: flex; width: 100%; align-items: center;">
      <div style="display: flex; flex-direction: column; gap: 3px">
        <span style="color: #6A7B95;font-size: 14px;font-weight: 500;">Ответить на сообщение</span>
        <div style="color: #191B23;">
          {{ quoteMessageText }}
        </div>
      </div>
      <span style="cursor: pointer; font-size: 20px;" class="pi pi-times" @click="clearEditValues"></span>
    </div>
  </div>

  <div v-if="editMessageId" class="edit-container">
    <IconEdit class="edit-container__icon" />
    <div style="justify-content: space-between; display: flex; width: 100%; align-items: center;">
      <div style="display: flex; flex-direction: column; gap: 3px">
        <span style="color: #6A7B95;font-size: 14px;font-weight: 500;">Редактировать сообщение</span>
        <div style="color: #191B23;">
          {{ editMessageText }}
        </div>
      </div>
      <span style="cursor: pointer; font-size: 20px;" class="pi pi-times" @click="clearEditValues"></span>
    </div>
  </div>
  <ChatInputWrapper
    ref="inputWrapper"
    :message="newChatMessage"
    :position="floatPosition"
    :is-send-disabled="isMessageSending"
    @select-emoji="onInputEmoji"
    @change-message="onInputMessage"
    @send-message="onSendMessage"/>
</template>

<script>
import { defineComponent } from 'vue';
import { getChannelConnector } from '@/core/channels/channel';
import { Icon } from '@brskl/ui-lib';
import IconReply from '@/assets/icons/icon_reply.svg';
import IconEdit from '@/assets/icons/icon_edit.svg';
import Chip from '@/apps/reports/component/Chip.vue';
import ChatInputWrapper from '@/core/components/Chat/ChatInputWrapper';
import ChatFilePreview from '@/core/components/Chat/ChatFilePreview';
import ChatAdminMessageHeader from '@/core/components/Chat/ChatAdminMessageHeader';
import ChatConfirmPopup from '@/core/components/Chat/ChatConfirmPopup';
import { supportAPI } from '@/core/api/_support';
import { companyAPI } from '@/core/api/company.js';

import { bus } from '@brskl/core';
import { format, compareAsc } from 'date-fns';

import ContextMenu from 'primevue/contextmenu';
import ConfirmPopup from 'primevue/confirmpopup';
import 'primeicons/primeicons.css'
import { mapActions, mapGetters } from 'vuex';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export default defineComponent({
  // @ts-ignore
  components: {
    ChatInputWrapper,
    ChatFilePreview,
    ChatAdminMessageHeader,
    ChatConfirmPopup,
    Icon,
    IconReply,
    IconEdit,
    Chip,
    ContextMenu,
    ConfirmPopup,
  },
  props: {
    detachedMode: {
      type: Boolean,
      default: false
    },
    floatPosition: {
      type: String,
      default: 'fixed',
      validate: (value) => ['fixed', 'absolute'].includes(value),
    },
  },

  emits: ['sendMessage', 'refetch'],

  data() {
    return {
      txt: 'txt',
      date: '20-04-2021',
      person: 'meme memev',
      newChatMessage: '',
      editMessageText: '',
      editMessageId: 0,
      quoteMessageId: 0,
      quoteMessageText: '',
      messages: [],
      contextMenuEntityTarget: {
        files: []
      },
      isMessageRemovingConfirmVisible: false,
      isMessageRetrySendConfirmVisible: false,
      isMessageSending: false,
      chat_message: {
        chat: {
          administrator_id: 48,
          company_id: 0,
          customer: null,
          employee: null,
          funnel: null,
          id: -1,
          last_comment: null,
          last_message: null,
          name: '',
          status: 1,
        },
        chat_id: 2633,
        created_at: this.formatDateToTime(new Date().toISOString()),
        files: [],
        id: -1,
        is_read: false,
        order: [],
        order_id: 0,
        reply_to_id: 0,
        reply_to_message: [],
        sender: {
          alias: 'administrator',
          id: 48,
          name: 'Briskly',
        },
        text: this.$t('support_service_greeting'),
        type: 0,
        scrollTimeout: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'shared$profile/profile',
    }),
    groupedMessages() {
      if (!this.messages) return [];
      const messagesGroupedByDate = {};
      const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');
      this.messages.forEach((message) => {
        const date = formatDate(message.created_at.split(' ').join('T'));
        if (!messagesGroupedByDate[date]) messagesGroupedByDate[date] = [];
        messagesGroupedByDate[date].push(message);
      });
      const result = [];
      Object.entries(messagesGroupedByDate).forEach(([date, messages]) => {
        result.push({
          date,
          messages,
        });
      });
      return result;
    },
    contextMenuOptions() {
      if (!this.contextMenuEntityTarget) {
        return [{ label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() }]
      }

      if (this.contextMenuOptions?.files?.length > 0) {
        return [
          { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
          { label: 'Удалить', icon: 'pi pi-trash', command: () => this.showMessageConfirmPopup() },
        ]
      }

      return [
        { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
        { label: 'Редактировать', icon: 'pi pi-pencil', command: () => this.startEditingMessage() },
        { label: 'Удалить', icon: 'pi pi-trash', command: () => this.showMessageConfirmPopup() },
      ]
    },
    replyMenuOption() {
      return [
        { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
      ]
    },
  },
  mounted() {
    this.initialize();
    bus.on('core$chat/newChatMessage', this.onChatMessage);
    window.addEventListener('focus', this.onWindowFocus);
  },
  unmounted() {
    clearTimeout(this.scrollTimeout);
    bus.off('core$chat/newChatMessage', this.onChatMessage);
    window.removeEventListener('focus', this.onWindowFocus);
  },
  methods: {
    ...mapActions({
      add: 'core$notifications/add',
      setChat: 'core$notifications/setChat',
    }),
    async initialize() {
      await this.fetchMessages();
      this.markAllMessagesAsRead();
    },
    clearEditValues() {
      this.contextMenuEntityTarget = {files: []};
      this.editMessageId = 0;
      this.editMessageText = '';
      this.newChatMessage = '';
      this.quoteMessageId = 0;
      this.quoteMessageText = '';
    },
    startEditingMessage() {
      const { id, text } = this.contextMenuEntityTarget;
      this.editMessageId = id;
      this.editMessageText = '' + text;
      this.newChatMessage = text;
    },
    startQuotingMessage() {
      const { id, text } = this.contextMenuEntityTarget
      this.quoteMessageId = id
      this.quoteMessageText = text
    },
    showMessageConfirmPopup() {
      this.isMessageRemovingConfirmVisible = true;
    },
    onMessageRemoveCancel() {
      this.isMessageRemovingConfirmVisible = false;
    },
    handleMessageSendError(error) {
      console.error(error);
      if (['ERR_NETWORK', undefined].includes(error.code)) {
        this.isMessageRetrySendConfirmVisible = true;
      }
    },
    async onMessageRemoveConfirm() {
      this.isMessageRemovingConfirmVisible = false;
      const { id } = this.contextMenuEntityTarget;
      this.deleteMessageById(id);
    },
    async updateMessage() {
      const response = await supportAPI.updateMessage({
        id: this.editMessageId,
        text: this.newChatMessage,
      });
      const updatedMessage = response.message;
      const message = this.findMessageById(this.editMessageId);
      if (!message) return;
      Object.assign(message, updatedMessage);
      this.newChatMessage = '';
      this.clearEditValues();
    },
    async replyToMessage() {
      await supportAPI.replyToMessage({
        text: this.newChatMessage,
        reply_to: this.quoteMessageId,
      });
      this.clearEditValues();
    },
    formatDateToTime(date) {
      const mappedDate = date.split(' ').join('T');
      return format(new Date(mappedDate), 'HH:mm');
    },
    onInputEmoji(emoji) {
      this.newChatMessage = this.newChatMessage + emoji.native
    },
    onInputMessage(message) {
      this.newChatMessage = message;
    },
    mapContextMenuEvent(event) {
      if (event instanceof TouchEvent) {
        const { touches } = event;
        if (!touches?.length) return event;
        return new Proxy(event, {
          get(target, prop) {
            if (['pageX', 'pageY'].includes(prop)) {
              return Math.floor(touches[0][prop]);
            }
            return typeof target[prop] === 'function'
              ? target[prop].bind(target)
              : target[prop];
          }
        });
      }
      return event;
    },
    onMessageRightClick(event, message) {
      event.stopPropagation();
      event.preventDefault();
      this.$refs.menu.show(this.mapContextMenuEvent(event));
      this.contextMenuEntityTarget = message;
    },
    onAdminMessageRightClick(event, message) {
      event.stopPropagation();
      event.preventDefault();
      this.$refs.replyMenu.show(this.mapContextMenuEvent(event));
      this.contextMenuEntityTarget = message;
    },
    formatChipToDayMonth(date) {
      if (!date) return '...';
      const mappedDate = date.split(' ').join('T');
      const day = format(new Date(mappedDate), 'dd');
      const monthTranslationKey = `MONTH_${format(new Date(mappedDate), 'MM')}`;
      return `${day} ${this.$t(monthTranslationKey)}`;
    },
    async sendNewChatMessage(text) {
      if (text.trim() === '') return;
      await companyAPI.sendMessage({ text });
      this.newChatMessage = '';
      this.$emit('sendMessage', this.newChatMessage);
      this.$emit('refetch');
    },
    async uploadFiles() {
      const { inputWrapper } = this.$refs;
      const files = inputWrapper.getAttachedFiles();
      if (!files?.length) return;
      const uploadPromises = files.map(async (file) => {
        const base64Contents = await toBase64(file);
        await supportAPI.uploadFile({ file: base64Contents });
        inputWrapper.removeAttachedFile(file);
      });
      await Promise.all(uploadPromises);
    },
    async onSendMessage() {
      if (navigator.onLine === false) {
        this.isMessageRetrySendConfirmVisible = true;
        return;
      }
      try {
        this.isMessageSending = true;
        this.isMessageRetrySendConfirmVisible = false;
        const requests = [];
        requests.push(this.uploadFiles());
        if (this.editMessageId) {
          requests.push(this.updateMessage());
        } else if (this.quoteMessageId) {
          requests.push(this.replyToMessage());
        } else {
          requests.push(this.sendNewChatMessage(this.newChatMessage));
        }
        await Promise.all(requests);
      } catch (error) {
        this.handleMessageSendError(error);
      } finally {
        this.isMessageSending = false;
      }
    },
    scrollToBottom() {
      this.$refs.end?.scrollIntoView();
    },
    async fetchMessages() {
      try {
        const { items } = await companyAPI.getMessages({
          limit: 9999,
          page: 1,
        });
        this.sortMessages(items);
        this.messages = items
          .filter((message) => message.is_delete || message.deleted_at)
          .map((message) => ({
            ...message,
            created_at: message.created_at.split(' ').join('T'),
          }));
        this.scrollTimeout = setTimeout(() => this.scrollToBottom(), 500);
      } catch (error) {
        console.error(error);
      }
    },
    findMessageById(id) {
      return this.messages.find((message) => {
        if (!message.id) return false;
        return message.id.toString() === id.toString();
      });
    },
    updateMessageFromSocket(socketMessage) {
      const message = this.findMessageById(socketMessage.id);
      if (!message) return undefined;
      return Object.assign(message, socketMessage);
    },
    deleteMessageById(messageId, { skipApiCall } = {}) {
      const message = this.findMessageById(messageId);
      if (message) {
        this.messages.splice(this.messages.indexOf(message), 1);
        if (!skipApiCall) {
          supportAPI.deleteMessage({ id: messageId });
        }
      }
    },
    async onChatMessage(data) {
      const message = data.chat_message;
      const defaultEmptyDate = '0000-00-00 00:00:00';
      if (message) {
        if (message?.is_delete) {
          return this.deleteMessageById(message.message.id, {
            skipApiCall: true,
          });
        }
        if (message?.is_update || message?.is_answer) {
          message.updated_at = defaultEmptyDate;
          return this.updateMessageFromSocket(message.message);
        }
      }
      if (!message.updated_at) {
        message.updated_at = message.created_at;
      }
      if (!message.deleted_at) {
        message.deleted_at = defaultEmptyDate;
        message.updated_at = message.created_at;
      }
      if (message.reply_to_message) {
        message.replied_message = message.reply_to_message;
      }
      if (this.findMessageById(message.id)) {
        this.updateMessageFromSocket(message);
      } else {
        this.messages.push(message);
        await this.handleSetMessageIsRead(message);
      }
      return message;
    },
    async handleSetMessageIsRead(message) {
      if (message.is_read) return message;
      if (!document.hasFocus()) return message;
      if (message.sender.id.toString() === this.currentUser.id.toString()) return message;
      await companyAPI.setAsRead({ id: message.id });
      const existingMessage = this.findMessageById(message.id);
      Object.assign(existingMessage, {
        is_read: true,
      });
      return existingMessage;
    },
    markAllMessagesAsRead() {
      this.messages.forEach((message) => this.handleSetMessageIsRead(message));
    },
    sortMessages(messages) {
      return messages.sort((m1, m2) => compareAsc(new Date(m1.created_at), new Date(m2.created_at)));
    },
    onWindowFocus() {
      getChannelConnector().reconnectIfClosed();
      this.markAllMessagesAsRead();
    },
  },
});
</script>

<i18n>
{
  "ru": {
    "message_remove_confirm_text": "Вы действительно хотите удалить это сообщение?",
    "message_network_error": "Нет подключения к интернету. Повторить отправку сообщения?",
    "retry_message_send": "Повторить"
  },
  "en": {
    "message_remove_confirm_text": "Are you sure you want to delete this message?",
    "message_network_error": "No internet connection. Retry sending the message?",
    "retry_message_send": "Retry"
  }
}
</i18n>

<style lang="scss" scoped>
.replied-message {
  border-left: 2px solid #22C951;
  font-size: 12px;
  color: #515C72;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.replied-message span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.italic {
  font-style: italic;
}

.edit-container {
  position: sticky;
  bottom: 64px;
  background: #E9EBF3;
  border-radius: 8px;
  display: flex;
  left: 0;
  right: 0;
  margin-left: 32px;
  margin-right: 32px;
  gap: 20px;
  padding: 14px 12px;
  align-items: center;

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: #515C72;
  }
}

.messages-container {
  height: 100%;
  flex-grow: 1;
  padding-bottom: 0;
}

:deep(.base-input__field) {
  padding-left: 35px !important;
}

.p-fileupload {
  position: absolute;
  top: 6px;
  bottom: 0;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  left: 10px;
  margin: auto;
}

:deep(.p-fileupload-header) {
  border: 0 !important;
}

.chat {
  --message-border-radius: 8px;

  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding-bottom: 150px;
}

.response {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  min-height: 72px;
  min-width: 243px;
  max-width: 600px;
  background: rgba(196, 211, 234, 0.5);
  border-radius: var(--message-border-radius);
  border-bottom-left-radius: 0;
  margin: 8px 32px;
  padding: 12px;
  word-break: break-all;

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
    align-self: flex-end;
  }

  &__container {
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
  }
}

.request {
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  min-height: 52px;
  min-width: 243px;
  max-width: 600px;

  background: #e9ebf3;
  border-radius: var(--message-border-radius);
  border-bottom-right-radius: 0;
  padding: 12px;
  margin: 8px 32px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    align-self: flex-start;
  }

  &__container {
    align-self: flex-end;
  }

  &__isread {
    width: 18px !important;
    height: 12px !important;
    margin: 0 6px 0 0;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
  }
}

.chip {
  width: 100px;
  white-space: nowrap;
  align-self: center;
  cursor: default;
  user-select: none;
  margin: 31px 0;
}

@media (max-width: 768px) {
  .request, .response {
    margin: 8px 16px;
  }
}
</style>
